import React, { Component } from 'react'
import welcomeReducer from './reducers/welcomeReducer';
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { createStore } from 'redux';
import { Provider } from 'react-redux';
import axios from 'axios';
import Welcome from './pages/Welcome';
import Product from './pages/Product';
import NewsArticle from './pages/NewsArticle';
import CustomWorks from './pages/CustomWorks';
import CustomWork from './pages/CustomWork';
import BuyingGuide from './pages/BuyingGuide';
import Faq from './pages/Faq';
import CartPage from './pages/CartPage';
import About from './pages/About';
import User from './pages/User';
import OrderNew from './pages/OrderNew';
import TrialFonts from './pages/TrialFonts';
import OrderShow from './pages/OrderShow';
import OrdersCreate from './pages/OrdersCreate';
import SignIn from './pages/SignIn';
import SignUp from './pages/SignUp';
import Password from './pages/Password';
import UserEdit from './pages/UserEdit';
import Products from './pages/Products';
import Terms from './pages/Terms';
import PrivacyPolicy from './pages/PrivacyPolicy';
import RefundPolicy from './pages/RefundPolicy';
import { checkWebFontLoad } from './utils';
import UpdatePassword from  './pages/UpdatePassword';


const renderMergedProps = (component, ...rest) => {
  const finalProps = Object.assign({}, ...rest);
  return (
    React.createElement(component, finalProps)
  );
}

const PropsRoute = ({ component, ...rest }) => {
  return (
    <Route {...rest} render={routeProps => {
      return renderMergedProps(component, routeProps, rest);
    }}/>
  );
}

export default class App extends Component {
  componentDidMount(){
    const tokenDom = document.querySelector("meta[name=csrf-token]")
    if (tokenDom) {
      const csrfToken = tokenDom.content
      axios.defaults.headers.common['X-CSRF-Token'] = csrfToken
    }

    checkWebFontLoad();
  }
  render() {

    return (
      <Provider store={createStore(welcomeReducer)}>
        <Router>
          <Switch>
            <PropsRoute exact path="/" component={Welcome} {...this.props} />
            <PropsRoute path="/about" component={About} {...this.props} />
            <PropsRoute path="/products/:permalink" component={Product} {...this.props} />
            <PropsRoute exact path="/products" component={Products} {...this.props} />
            <PropsRoute exact path="/cart" component={CartPage} {...this.props} />
            <PropsRoute exact path="/news" component={NewsArticle} {...this.props} />
            <PropsRoute path="/news/:id" component={NewsArticle} {...this.props} />
            <PropsRoute exact path="/custom-work" component={CustomWorks} {...this.props} />
            <PropsRoute path="/custom-work/:id" component={CustomWork} {...this.props} />
            <PropsRoute exact path="/faq" component={Faq} {...this.props} />
            <PropsRoute exact path="/trial-fonts" component={TrialFonts} {...this.props} />
            <PropsRoute path="/users/password/edit" component={Password} {...this.props} />
            <PropsRoute exact path="/users/password/new" component={Password} {...this.props} />
            <PropsRoute exact path="/users/password" component={Password} {...this.props} />
            <PropsRoute exact path="/users/sign_in" component={SignIn} {...this.props} />
            <PropsRoute exact path="/users/sign_up" component={SignUp} {...this.props} />
            <PropsRoute exact path="/users" component={SignUp} {...this.props} />
            <PropsRoute exact path="/users/update_password" component={UpdatePassword} {...this.props} />

            <PropsRoute exact path="/users/:id" component={User} {...this.props} />
            <PropsRoute exact path="/order/new" component={OrderNew} {...this.props} />
            <PropsRoute exact path="/order/:public_uid/success" component={OrdersCreate} {...this.props} />
            <PropsRoute exact path="/order/:public_uid" component={OrderShow} {...this.props} />
            <PropsRoute exact path="/terms" component={Terms} {...this.props} />
            <PropsRoute exact path="/privacy-policy" component={PrivacyPolicy} {...this.props} />
            <PropsRoute exact path="/refund-policy" component={RefundPolicy} {...this.props} />
          </Switch>
        </Router>
      </Provider>
    )
  }
}
