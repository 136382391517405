import React from 'react';
import _ from 'lodash';
import axios from 'axios';
import { MobileMenu, Menu, ProductVariantPreview, Cart, MenuTypefaces, MenuScripts, Footer, BackgroundColorChanger, ProductVariantLicenseSelector, BwBuyBar, ProductVariantPreviewArea, CartAddedModal } from '../components';
import { connect } from 'react-redux';
import { windowResize, changeMobileMenuColors, changeBwMode, changeLoginInfo, setCart, changeUpProductVariantName, changeDownProductVariantName, changeUpProductVariantSupportedLanguage, changeDownProductVariantSupportedLanguage, changeCartModal } from '../actions';
import { Container, Gutter, PrimaryButton, ButtonA, ButtonAPrice, ButtonAWhite } from '../stylesheets/components'
import { numberWithDelimiter, calculateDiscountRate, calculateItemTotal, calculateTotal } from '../utils';
import styled from 'styled-components';
import media from '../stylesheets/media';

const Fragment = React.Fragment;

const FinalButtonArea = styled.div`
  display: flex;
  ${media.mobileLarge`
    justify-content: center;

  `}

`;




const FinalPrice = styled.div`
  color: ${props => props.bwMode === "black" ? "white" : "black"};
`;

const ProductHalfContainer = styled.div`
  width: 100vw;
  display: flex;
  justify-content: space-between;
  /* border-top: 1px solid white; */

  ${media.mobileLarge`
    display: block;
    width: 100vw;
  `}
`;
const ProductHalfColumn = styled.div`
  width: calc(50vw - 4px);

  ${media.mobileLarge`
    width: calc(100% - 30px);
    padding: 20px 15px;
  `}

  &.right {
    width: calc(50vw + 4px);
    border-left: 1px solid ${props => props.bwMode === "black" ? "white" : "black"};

    ${media.mobileLarge`
      width: calc(100% - 30px);
      border: none;
      border-top: 1px solid ${props => props.bwMode === "black" ? "white" : "black"};
    `}
  }

  p {
    color: ${props => props.bwMode === "black" ? "white" : "black"};
  }

  a.white {
    color: ${props => props.bwMode === "black" ? "white" : "black"};
  }


  a.pdf-link {
    color: ${props => props.bwMode === "black" ? "white" : "black"};
    font-weight: normal;
    border-bottom: 1px solid ${props => props.bwMode === "black" ? "white" : "black"};
  }
  div.desc {
    margin-bottom: 10px;
  }
  div.desc div {
    /* font-size:0.9em; */
    color: ${props => props.bwMode === "black" ? "white" : "black"};
  }

  div.p40 {
    padding: 24px;

    color: ${props => props.bwMode === "black" ? "white" : "black"};

    ${media.mobileLarge`
      padding: 0;
      width: 100%;
    `}
  }

  div.available-scripts-area {
    line-height: 48px;
  }

`;

const ScriptAnchor = styled.button`
  display: inline-block;
  border:none;
  background:transparent;
  margin-right: 10px;
  cursor:pointer;
  font-size:1.2em;
  line-height: 1;
  border: 1px solid ${props => props.bwMode === "black" ? "white" : "black"};
  background: ${props => props.bwMode === "black" ? "black" : "white"};
  color: ${props => props.bwMode === "black" ? "white" : "black"};
  padding: 10px;

  &.selected {
    border: 1px solid ${props => props.bwMode === "black" ? "black" : "white"};
    background: ${props => props.bwMode === "black" ? "white" : "black"};
    color: ${props => props.bwMode === "black" ? "black" : "white"};
  }
`;
const ProductBtnArea = styled.div`
  display: flex;
  margin-top: 15px;

  ${media.mobileLarge`
    justify-content: center;
  `}

`
const ProductBtn = styled.a`
  display: block;
  border: 1px solid ${props => props.bwMode === "black" ? "white" : "black"};
  color: ${props => props.bwMode === "black" ? "white" : "black"};
  background: transparent;
  border-radius: 30px;
  padding: 7px 20px;
  font-size: 1.0em;
  line-height:1.2;
  margin-right: 15px;

  &.last-child {
    margin: 0;
  }
`;

const CustomPlanDesc = styled.div`
  font-size: 0.8em;
  color: #828282;
  line-height:1.2;
  margin: 15px 0;
`;

const IndicDesc = styled.div`
font-size: 0.8em;
color: #828282;
line-height:1.2;
margin: 5px 0;
`;

const ProductInfoArea = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 40px;
  div.left {
    width: 120px;
    color: ${props => props.bwMode === "black" ? "white" : "black"};
  }

  div.right {
    width: calc(100% - 120px);
    color: ${props => props.bwMode === "black" ? "white" : "black"};
  }


  div.desc  {
    div {
      color: ${props => props.bwMode === "black" ? "white" : "black"};
    }
  }

  ${media.mobileLarge`
    margin-top: 10px;
  `}

`;

const VariantSelectorArea = styled.div`
  border: 1px solid ${props => props.bwMode === "black" ? "white" : "black"};
  a {
    display:flex;
    justify-content: space-between;
    padding: 15px;
    border-bottom: 1px solid ${props => props.bwMode === "black" ? "white" : "black"};

    ${media.mobileLarge`
      display: block;
    `}

    &:hover {
      opacity: 1;
      div.area {
        color: ${props => props.bwMode === "black" ? "rgba(255, 255, 255, 0.5)" : "rgba(0, 0, 0, 0.5)"};
      }

      border-bottom: 1px solid ${props => props.bwMode === "black" ? "white" : "black"};
    }
  }

  div.area {
    color: ${props => props.bwMode === "black" ? "white" : "black"};
  }


  span.languages {
    color: #828282;
    margin-right: 15px;
  }
  span.price {
    color: #828282;
  }
  span.typenames {
  }

  a.selected {
    color: ${props => props.bwMode === "black" ? "black" : "white"};
    background-color: ${props => props.bwMode === "black" ? "white" : "black"};
    border:none;

    div.area {
      color: ${props => props.bwMode === "black" ? "black" : "white"};
    }

    div {
      color: black;
    }
  }

  a:last-child {
    border-bottom: none;
  }
`;

const LanguageLabel = styled.span`
  &:after {
    content: ", "
  }

  &:last-child {
    &:after {
      content: none;
    }
  }
`;

class Product extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedVariants: {},
      selectedLanguages: {},
      fontLoaded: false
    };

    if (this.props.product.default_language) {
      this.state.selectedLanguages[this.props.product.default_language.id] = { ... this.props.product.default_language };
    }

    this.props.dispatch(changeLoginInfo(
      this.props.new_user_session_path,
      this.props.current_user,
      this.props.user_signed_in,
      this.props.destroy_user_session_path
    ));

    // this.props. update Cart
    this.props.dispatch(setCart(this.props.cart));

    this.props.dispatch(changeMobileMenuColors("#000000", "#FFFFFF"));

    // console.log(downProductVariantID);
    // console.log("downVariant");

    this.props.dispatch(changeUpProductVariantName({
      value: this.props.product.up_product_variant_name,
      label: this.props.product.up_product_variant_name
    }))
    this.props.dispatch(changeDownProductVariantName({
      value: this.props.product.down_product_variant_name,
      label: this.props.product.down_product_variant_name
    }))

    this.props.dispatch(changeDownProductVariantSupportedLanguage({
      value: this.props.product.down_product_variant_language.id,
      label: this.props.product.down_product_variant_language.language_name
    }))
    this.props.dispatch(changeUpProductVariantSupportedLanguage({
      value: this.props.product.up_product_variant_language.id,
      label: this.props.product.up_product_variant_language.language_name
    }))

  }


  handleNumberLicenseClick(variant, selectedNumberLicense){

    // step 1:  기존 selectedNumberLicenses 를 license_id에 맞추어 object를 나눈다
    let selectedVariant = this.state.selectedVariants[variant.id];
    let selectedNumberLicenseByLicenseID = {};

    _.each(selectedVariant.selectedNumberLicenses, sl => {
      selectedNumberLicenseByLicenseID[sl.license_id] = sl;
    });

    // step 2: license_id 가 있으면 덮어쓴다
    if (selectedNumberLicense.value.deleted) {
      delete selectedNumberLicenseByLicenseID[selectedNumberLicense.value.license_id];
    } else {
      selectedNumberLicenseByLicenseID[selectedNumberLicense.value.license_id] = selectedNumberLicense.value;
    }

    // debugger;

    // step 3: [id]: value 로 정리

    let finalSelectedLicenses = {};
    _.each(selectedNumberLicenseByLicenseID, (v, k) => {
      finalSelectedLicenses[v.id] = v;
    });

    // if (finalSelectedLicenses)

    selectedVariant.selectedNumberLicenses = finalSelectedLicenses;

    if (_.isEmpty(selectedVariant.selectedNumberLicenses)){
      // none으로 변했으므로 삭제

      let removedSelectedVariants = {
        ...this.state.selectedVariants
      };

      delete removedSelectedVariants[selectedVariant.id];


      this.setState({
        selectedVariants: removedSelectedVariants
      });
    } else {


      this.setState({
        // variants
        selectedVariants: {
          ...this.state.selectedVariants,
          [variant.id]: selectedVariant
        }
      });

    }


  }

  handleLanguageClick(language){
    let keys = _.keys(this.state.selectedLanguages);
    let isAlreadySelected = _.includes(keys, String(language.id));

    if (isAlreadySelected) {

      let removedSelectedLanguages = {
        ...this.state.selectedLanguages
      };
      delete removedSelectedLanguages[language.id];

      this.setState({
        selectedLanguages: removedSelectedLanguages
      });

    } else {

      this.setState({
        selectedLanguages: {
          ...this.state.selectedLanguages,
          [language.id] : {
            ...language
          }
        }
      });
    }
  }

  handleVariantClick(variant){
    let keys = _.keys(this.state.selectedVariants);
    let isAlreadySelected = _.includes(keys, String(variant.id));
    let isCompleteIncluded = false;
    let completeVariantID;

    _.each(this.state.selectedVariants, variant => {
      if (variant.complete) {
        isCompleteIncluded = true;
        completeVariantID = variant.id;
      }
    })

    if (isAlreadySelected) {

      let removedSelectedVariants = {
        ...this.state.selectedVariants
      };
      delete removedSelectedVariants[variant.id];

      this.setState({
        selectedVariants: removedSelectedVariants
      });

    } else {

      // if (variant.complete) {

      //   this.setState({
      //     selectedVariants: {
      //       [variant.id] : {
      //         ...variant,
      //         selectedNumberLicenses: [_.first(_.first(this.props.licenses).number_licenses)]
      //       }
      //     }
      //   });

      // } else {
        // _.first(variant.product_variants_number_licenses)

        let removedSelectedVariants = {
          ...this.state.selectedVariants
        };

        if (isCompleteIncluded) {

          delete removedSelectedVariants[completeVariantID];

        }


        this.setState({
          selectedVariants: {
            ...removedSelectedVariants,
            [variant.id] : {
              ...variant,
              selectedNumberLicenses: [_.first(_.first(this.props.licenses).number_licenses)]
            }
          }
        });
      // }


    }

  }

  componentDidMount() {
    window.addEventListener('resize', this.resizeHandler.bind(this));
    this.resizeHandler();
    this.checkFontLoad();
  }

  checkFontLoad(){
    this.fontInterval = setInterval(() => {
      if (document.fonts.check("12px 'UT Sans'")){
        clearInterval(this.fontInterval);

        this.setState({
          fontLoaded: true
        })
      }
    }, 100);
  }

  componentWillUnmount(){
    clearInterval(this.fontInterval);
  }


  resizeHandler(e) {
    this.props.dispatch(windowResize({
      width: window.innerWidth,
      height: window.innerHeight
    }));

  }
  getFinalPrice(){

    let { selectedVariants } = this.state;

    if (!_.isEmpty(selectedVariants)) {

      return calculateTotal(selectedVariants);

    } else {

      return null;
    }
  }

  handleAddCartClick (e){
    // debugger;

    let number_license_ids = {};

    _.each(this.state.selectedVariants, v => {
      number_license_ids[v.id] = _.map(v.selectedNumberLicenses, snl => snl.id);
    })

    let productVariantIds = _.keys(this.state.selectedVariants);

    axios.post("/api/cart_items.json", {
      product_variant_ids: productVariantIds,
      number_license_ids: number_license_ids,
      cart_id: this.props.cart.id
    }).then((response) => {
      if (response.data.success){
        this.setState({
          selectedNumberLicenses: {},
          selectedVariants: {}
        });
        this.props.dispatch(setCart(response.data.cart));
        this.props.dispatch(changeCartModal(true, productVariantIds.length));
        // document.location.href = "/cart";

        if (response.data.errors) {
          // console.log(response.data.errors);
        }
      }
    })

  }

  determineLanguageSelection(language) {
    return _.includes(_.keys(this.state.selectedLanguages), String(language.id));
  }

  render() {
    let { cartModal, licenses, product, cart, bwMode, products, languages, typefacesPopup } = this.props;
    let { fontLoaded } = this.state;
    let finalPrice = this.getFinalPrice();

    let individualVariants = _.filter(product.variants, v => {
      return !v.complete;
    });
    console.log(product)


    let sortedVariants = [];

    let completeVariant = _.find(product.variants, v => v.complete);

    if (completeVariant) {
      sortedVariants.push(completeVariant);
    }

    _.each(_.filter(product.variants, v => !v.complete), v => {
      sortedVariants.push(v);
    });

    let keyLanguages = _.keys(this.state.selectedLanguages);
    sortedVariants = _.filter(sortedVariants, v => {
      let productVariantLanguageIds = _.map(v.languages, l => { return String(l.id) });
      let diff = _.difference(productVariantLanguageIds, keyLanguages);
      return productVariantLanguageIds.length > diff.length;
    })

    sortedVariants = _.orderBy(sortedVariants, v => {
      return _.map(v.languages, l => l.language_name).join(", ");
    })

    sortedVariants = sortedVariants.sort((a,b) => a.idx - b.idx)
    let default_language_id = null;

    if (product.default_language) {
      default_language_id = product.default_language.id;
    }


    return (
      <Fragment>
        {
          cartModal ?
          <CartAddedModal /> : null
        }
        <Menu foregroundColor={bwMode === "white" ? "#000000" : "#FFFFFF"} isLogoutEnabled={true} />
        <MenuTypefaces foregroundColor={bwMode === "white" ? "#000000" : "#FFFFFF"} backgroundColor={bwMode === "black" ? "#000000" : "#FFFFFF"} products={products} />
        <MenuScripts foregroundColor={bwMode === "white" ? "#000000" : "#FFFFFF"} languages={languages} />

        {
          typefacesPopup ?
          null :
          <Fragment>
            <BackgroundColorChanger color="white" bwMode="white" />
            <Container bwMode={bwMode} style={{ opacity: fontLoaded ? 1 : 0 }} className="no-webfont">
              <ProductVariantPreviewArea {...this.props} />


              <ProductHalfContainer>
                <ProductHalfColumn bwMode={bwMode}>
                  <div className="p40">
                    <div className="desc" dangerouslySetInnerHTML={{ __html: product.description }} />

                    <ProductInfoArea bwMode={bwMode}>
                        <div className="left">
                          Year<br />
                          {
                            product.year
                          }
                        </div>
                      <div className="right">

                        <div className="desc" dangerouslySetInnerHTML={{ __html: product.designer }} />
                      </div>

                    </ProductInfoArea>
                    <ProductBtnArea>
                      <ProductBtn bwMode={bwMode} href="/trial-fonts">Trial Fonts</ProductBtn>
                      <ProductBtn bwMode={bwMode} href={ product.pdf_specimen }>PDF Specimen</ProductBtn>
                    </ProductBtnArea>
                  </div>




                </ProductHalfColumn>
                <ProductHalfColumn bwMode={bwMode} className="right">
                  <a name="buy"></a>
                  <div className="p40">
                    <h4>
                      Available Scripts
                    </h4>

                    {product.latin_support  &&
                      <IndicDesc>
                        All indic files come with basic Latin support
                      </IndicDesc>
}

                    <div className="available-scripts-area">
                      {
                        _.map(product.languages, l => {
                          return (
                            <ScriptAnchor bwMode={bwMode} onClick={this.handleLanguageClick.bind(this, l)} className={this.determineLanguageSelection(l) ? "selected" : ""} key={l.language_name}>
                              { l.language_name }
                            </ScriptAnchor>
                          );
                        })
                      }
                    </div>
                    <Gutter h={30} />
                    <h4>
                      Buying Options
                    </h4>
                    <Gutter h={10} />
                    <VariantSelectorArea bwMode={bwMode}>
                      {
                        _.map(sortedVariants, variant => {
                          let selected = "";
                          let selectedVariant = null;
                          let priceLabel = `from €${numberWithDelimiter(variant.price)}`;



                          if (!_.isEmpty(this.state.selectedVariants)) {
                            let keys = _.keys(this.state.selectedVariants);
                            if (_.includes(keys, String(variant.id))) {
                              selected = "selected";
                            }
                          }

                          if (selected === "selected") {
                            selectedVariant = _.find(this.state.selectedVariants, sv => {
                              return sv.id === variant.id;
                            });
                            priceLabel = `€${numberWithDelimiter(calculateItemTotal(selectedVariant))}`;
                          }

                          if (variant.complete) {
                            return (
                              <Fragment key={variant.id}>

                                <a className={selected} href="javascript:void(0);" onClick={this.handleVariantClick.bind(this, variant)}>
                                  <div className="area">
                                    <span className="languages">
                                    {
                                      _.map(variant.languages, l => {
                                        return (
                                          <LanguageLabel key={l.id}>
                                            { l.language_name }
                                          </LanguageLabel>
                                        );
                                      })
                                    }
                                    </span>
                                    <span className="typenames">
                                      {product.product_name} Complete Family
                                    </span>
                                  </div>
                                  <div className="area">
                                    <span className="price">
                                      (-{calculateDiscountRate(product.variants, variant)}%) {priceLabel}
                                    </span>
                                  </div>
                                </a>
                                <ProductVariantLicenseSelector
                                  selected={selected === "selected"}
                                  licenses={licenses}
                                  variant={selectedVariant}
                                  handleNumberLicenseClick={this.handleNumberLicenseClick.bind(this)}
                                />

                              </Fragment>
                            );
                          } else {
                            return (
                              <Fragment key={variant.id}>

                                <a className={selected} href="javascript:void(0);" onClick={this.handleVariantClick.bind(this, variant)}>
                                  <div className="area">
                                    <span className="languages">
                                      {
                                        _.map(variant.languages, l => {
                                          return (
                                            <LanguageLabel key={l.id}>
                                              { l.language_name }
                                            </LanguageLabel>
                                          );
                                        })
                                      }
                                    </span>
                                    <span className="typenames">
                                      {product.product_name} {variant.variant_name}
                                    </span>
                                  </div>
                                  <div className="area">
                                    <span className="price">
                                      {priceLabel}
                                    </span>
                                  </div>
                                </a>

                                <ProductVariantLicenseSelector
                                  selected={selected === "selected"}
                                  licenses={licenses}
                                  variant={selectedVariant}
                                  handleNumberLicenseClick={this.handleNumberLicenseClick.bind(this)}
                                />
                              </Fragment>
                            );
                          }

                        })
                      }
                    </VariantSelectorArea>

                    <CustomPlanDesc>
                      For other types of licenses, e.g. for gaming, epublishing, enterprise and OEM, <br/>
                      please contact us at <a className="white" href="mailto:hello@universalthirst.com">hello@universalthirst.com</a>
                    </CustomPlanDesc>

                    {
                      _.isNull(finalPrice) ?
                      null :
                      <FinalButtonArea>
                        <ButtonAPrice bwMode={bwMode}>
                          €{ numberWithDelimiter(finalPrice) }
                        </ButtonAPrice>
                        <ButtonAWhite bwMode={bwMode} href="javascript:void(0);" onClick={this.handleAddCartClick.bind(this)}>Add to Cart</ButtonAWhite>
                      </FinalButtonArea>
                    }


                  </div>
                </ProductHalfColumn>
              </ProductHalfContainer>

            </Container>
            <Footer />
          </Fragment>
        }
        <MobileMenu isLogoutEnabled={true} backgroundColor="black" foregroundColor="white" highlightColor="#FFFFFF" currentPage="Cart" />
      </Fragment>


    );
  }
}


let mapStateToProps = state => {
  return {
    bwMode: state.bwMode,
    cartModal: state.cartModal,
    typefacesPopup: state.typefacesPopup
  };
}

export default connect(mapStateToProps)(Product);
